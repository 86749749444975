import axios from "axios";
import { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import productImg from "../../icons/product.svg";
import { productSlice } from "../../slices/product";
import { url } from "../../utils/url";
import {
  faCaretUp,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "react-responsive";
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";

const ImageGallery = ({ productImage }) => {
console.log(productImage)
  // const mobileView = useMediaQuery({ query: "(max-width: 576px)" });



  return (
    <div className="detail-images">
      
    
    {
      productImage.map((item)=>(

      
     <div className="container">
       <div className="a">
       
       {item ? (
         <img
           src={item.imageURI}
           alt="Example"
      
           // alwaysInPlace={true}
           //  largeImageSrc={activeImageUrl} // Optional
         />
       ) : (
          <img className="img-fluid" src="a.jgp" alt="" />
         // <Skeleton
           // style={{
           //   position: "relative",
           //   width: "100%",
           //   height: "0",
           //   paddingBottom: "100%",
           // }}
         // />
       )}
       
     </div>
     <div className="contain-small">
     <div className="small">
      
      {item ? (
        <img
          src={item.imageURI}
          alt="Example"
     
          // alwaysInPlace={true}
          //  largeImageSrc={activeImageUrl} // Optional
        />
      ) : (
         <img className="img-fluid" src="a.jgp" alt="" />
        // <Skeleton
          // style={{
          //   position: "relative",
          //   width: "100%",
          //   height: "0",
          //   paddingBottom: "100%",
          // }}
        // />
      )}
    </div>
    <div className="small2">
      
     {item ? (
       <img
         src={item.imageURI}
         alt="Example"
    
         // alwaysInPlace={true}
         //  largeImageSrc={activeImageUrl} // Optional
       />
     ) : (
        <img className="img-fluid" src="a.jgp" alt="" />
       // <Skeleton
         // style={{
         //   position: "relative",
         //   width: "100%",
         //   height: "0",
         //   paddingBottom: "100%",
         // }}
       // />
     )}
   </div>
   <div className="small3">
      
     {item ? (
       <img
         src={item.imageURI}
         alt="Example"
    
         // alwaysInPlace={true}
         //  largeImageSrc={activeImageUrl} // Optional
       />
     ) : (
        <img className="img-fluid" src="a.jgp" alt="" />
       // <Skeleton
         // style={{
         //   position: "relative",
         //   width: "100%",
         //   height: "0",
         //   paddingBottom: "100%",
         // }}
       // />
     )}
   </div>
     </div>
     </div>
      ))
}
    </div>
  );
};

export default ImageGallery;
