import React, { useState, useEffect } from "react";
import axios from "axios";
import { url, url2 } from "../../utils/url";
import { Carousel } from "react-bootstrap";
const HomeCarousel = () => {
  const [ads, setAds] = useState({
    spaceOneAds: [],
    spaceTwoAds: [],
    spaceThreeAds: [],
  });
  const { spaceOneAds, spaceTwoAds, spaceThreeAds } = ads;
  console.log(spaceThreeAds);
  React.useEffect(() => {
    getAllAds();
  }, []);

  const getAllAds = () => {
    axios.get(`${url2}get-all-ads`).then((response) => {
      if (response.data.err) {
        console.log(response.data.err);
      } else {
        const adOne = response.data.result.filter((ad) => ad.adSpace == "one");
        const adTwo = response.data.result.filter((ad) => ad.adSpace == "two");
        const adThree = response.data.result.filter(
          (ad) => ad.adSpace == "three"
        );
        setAds({
          spaceOneAds: adOne,
          spaceTwoAds: adTwo,
          spaceThreeAds: adThree,
        });
      }
    });
  };
  return (
    <div className="home-carousel">
      <Carousel>
        {spaceTwoAds.map((item, index) => {
          return (
            <Carousel.Item>
              <div key={index}>
                <a href={item.link} alt="link">
                  <img
                    className="d-block w-100"
                    src={item.imageURI}
                    style={{ height: "460px" }}
                    alt="First slide"
                  />
                </a>
              </div>
            </Carousel.Item>
          );
        })}
        {/* <Carousel.Caption>
      <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption> */}
      </Carousel>
    </div>
  );
};
export default HomeCarousel;